import React from 'react'
import Layout from "../../components/layout"


export default function Akeneo() {
  return (
    <Layout>
      <h1>Akeneo</h1>
    </Layout>
  )
}